<template>
  <div class="page">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>智能填报</el-breadcrumb-item>
        <el-breadcrumb-item>普通类型填报</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="info">
      <el-row>
        <el-col :span="18">
          <span>科类:</span>
          <span class="info_2">{{ userInfo.is_wenli == 1 ? '文科' : '理科' }}</span>
          <span>分数:</span>
          <span class="info_2">{{ userInfo.score }}分</span>
          <span>位次:&nbsp;</span>
          <span class="info_2">{{ equalScore[0] }}</span>
          <span>我的同位分：</span>
          <span class="info_3">{{ equalScore[1] }}分</span>
          <span class="info_4">/23年</span>
          <span class="info_3">{{ equalScore[2] }}分</span>
          <span class="info_4">/22年</span>
          <span class="info_3">{{ equalScore[3] }}分</span>
          <span class="info_4">/21年</span>
        </el-col>

        <el-col :span="4" :offset="2">
          <span>填报批次：</span>
          <span class="tuijian"></span>
          <span class="select">
            <el-select v-model="batchs" placeholder="请选择" class="searchBatch" @change="getBatch" size="mini">
              <div v-for="(item, i) in batchList" :key="item.id">
                <el-option :label="item.name" :value="item.id" v-if="userInfo.score - ScoreLine[i] >= 0">
                </el-option>
                <el-option :label="item.name" :value="item.id" v-else disabled>
                </el-option>
              </div>
            </el-select>
          </span>
        </el-col>
      </el-row>

    </div>
    <div class="nav">
      <div :class="{ act: this.nav_num == 0 }" @click="chooseNav(0)">
        <p>AI智能填报</p>
        <p>一键生成志愿方案</p>
      </div>
      <div :class="{ act: this.nav_num == 1 }" @click="chooseNav(1)">
        <p>院校优先填报</p>
        <p>以院校为优先级填报志愿</p>
      </div>
      <div :class="{ act: this.nav_num == 2 }" @click="chooseNav(2)">
        <p>专业优先填报</p>
        <p>以专业为优先级填报志愿</p>
      </div>
      <div :class="{ act: this.nav_num == 3 }" @click="chooseNav(3)">
        <p>我的志愿表</p>
        <p>当前已填{{ college_num }}志愿位置</p>
      </div>
    </div>

    <router-view @Nav="setNav" v-if="batch"></router-view>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "Index",
  computed: { ...mapState(["userInfo", 'batch']) },
  data() {
    return {
      nav_num: 0,
      batchList: [
        { id: "1", name: "本科一批", code: "1", status: "1" },
        { id: "2", name: "本科二批", code: "2", status: "1" },
        { id: "3", name: "高职高专批", code: "3", status: "1" },
      ],
      batchs: '',
      is_wenli: 0,
      college_num: 0,
      equalScore: [0, 0, 0, 0],
      ScoreLine: [0, 0, 0],
    };
  },
  created() {
    //设置批次 - 文理科
    this.is_wenli = this.userInfo.is_wenli
    // 获取批次线
    this.getLineList();

    // 获取等同分
    this.getEqualList();

  },
  methods: {
    ...mapMutations(['setEqualScore', 'setOldLineScore', 'setRecore', 'setTable', 'setBatch', 'setScoreLine']),

    //动态选择模块
    chooseNav(e) {
      this.nav_num = e;
      switch (e) {
        case 0:
          this.$router.push("/volunteer");
          return;
        case 1:
          this.$router.push("/volunteer/CollegeFirst");
          return;
        case 2:
          this.$router.push("/volunteer/MajorFirst");
          return;
        case 3:
          this.$router.push("/volunteer/ReportTable");
          return;
      }
    },

    setNav(e) {
      this.nav_num = e;
    },

    // 获取批次线
    getLineList() {
      this.$fecth
        .post("volunteer/getScoreLine", {
          is_wenli: this.userInfo.is_wenli,
        })
        .then((res) => {
          this.ScoreLine = res;
          this.setScoreLine(this.ScoreLine)
          this.defalutBatch();
        });

    },

    //设置默认批次
    defalutBatch() {

      //如果没有设置批次 获取默认批次
      if (!this.batch) {
        if (this.userInfo.score > this.ScoreLine[0]) {
          this.changeBatch("1");
        } else if (this.userInfo.score > this.ScoreLine[1]) {
          this.changeBatch("2");
        } else {
          this.changeBatch("3");
        }
      } else {
        this.changeBatch(this.batch)
      }
      this.getOldCityScore()
    },

    getBatch() {
      this.setBatch(this.batchs)
      this.changeBatch(this.batchs)
    },

    //设置批次
    changeBatch(e) {
      this.batchs = e
      this.setBatch(e)
      this.getOldCityScore()
    },

    //获取往年录取线
    getOldCityScore() {
      this.$fecth
        .post("common/getOldLineScore", {
          is_wenli: this.userInfo.is_wenli,
          batch: this.batchs,
        })
        .then((res) => {
          this.oldLineScore = res;
          this.setOldLineScore(this.oldLineScore)
        });
    },

    // 获取等同分
    getEqualList() {
      this.$fecth
        .post("score_equal/getEqualScore", {
          is_wenli: this.userInfo.is_wenli,
          score: this.userInfo.score,
        })
        .then((res) => {
          this.equalScore = res;
          this.setEqualScore(this.equalScore)
        });
    },

  },
};
</script>

<style lang="less">
.searchBatch {
  .el-input__inner {
    border: 1px solid #2b7bf3;
    border-radius: 30px;
    height: 30px;
    line-height: 30px;
    width: 110px;
  }

  .el-select:hover {
    border-color: #2b7bf3 !important;
  }

  .el-input__icon {
    line-height: 30px !important;
  }
}
</style>
<style lang="less">
.el-input__inner {
  font-size: 16px;
}
</style>

<style lang="less" scoped>
.nav {
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;

  div {
    width: 219px;
    background: #459df6;
    padding: 8px 57px;

    p:first-child {
      color: white;
      font-size: 18px;
    }

    p:last-child {
      font-size: 14px;
      color: white;
    }
  }
}

.act {
  background: #2871fb !important;
  color: white;
}

.info {
  font-size: 16px;
  position: relative;

  span {
    display: inline-block;
  }

  margin: 0 0 20px 0;

  .info_2 {
    color: #1787e0;
    margin-right: 25px;
  }

  .info_3 {
    color: #ff6600;
    margin-left: 15px;
  }

  .info_4 {
    color: #a5a3a2;
  }

  .tuijian {
    position: absolute;
    height: 20px;
    width: 55px;
    top: -16px;
    right: 10px;
    font-size: 10px;
    background: url("../../assets/img/volunteer/tuijianpc.png") no-repeat;
  }
}
</style>